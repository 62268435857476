/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import './Contact.css'
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
const Career = () => {
  return (
    <div>
    <Helmet>
        <title>Converging Miinds</title>
        <meta name="Contact Accounting Experts | converging miinds Consultation" content="Connect with our trusted accounting and audit professionals. Get expert financial advice, schedule a consultation, & solve your business's financial challenges." />
      </Helmet>
        {/* <div className="contact-banner">
          <div className="banner-content cust-container">
            <p className='sub-banner-htag contact'>Let's Turn Ideas into 
            <br /><span className='highlight'>Impact. Talk to us now</span></p>
          </div>
        </div> */}

        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className="row d-flex justify-content-between">
            <div className="col-md-6 col-sm-12 below-banner one">
                <p className="h3 fw-bold">Address</p>
                <p>#309, 1st Main Rd, Ganga Nagar Layout, Ganganagar, Bengaluru, Karnataka 560032.</p>
            </div>
            <div className="col-md-6 col-sm-12 below-banner two">
              <p className="h3 fw-bold">Contact Number</p>
              <p><a href="tel:9900030931" style={{color: "white"}}>+91 99000 30931</a></p>
              <p className="h3 fw-bold">Email</p>
              <a href="mailto:info@convergingmiinds.com" style={{color: "white", fontSize: "18px"}}>info@convergingmiinds.com</a>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
        <div className="contact-container">
          <h2 className="contact-header htag">Contact us</h2>
      
      <Form className="contact-form">
        <Row>
          
          <p className="contact-description">We're more than just a conversation. We're the bridge between your vision and digital success. <br/> Reach out, and 
          let's start transforming potential into performance.</p>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Your Name*</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formLocation">
              <Form.Label>Your Email*</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formContactName">
              <Form.Label>Your Address*</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formContactPhone">
              <Form.Label>Contact Number*</Form.Label>
              <Form.Control type="tel" />
            </Form.Group>
          </Col>

          </Row>
          <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formMessage">
            <Form.Label>Write your message</Form.Label>
            <Form.Control as="textarea" rows={3}  />
          </Form.Group>
          </Col>
          </Row>
          <Row>
         
        </Row>
        
        
        
        <Form.Group className="mb-3" controlId="formNewsletter">
          <Form.Check type="checkbox" label="I want to protect my data by signing an NDA" />
        </Form.Group>
        <Button variant="primary" type="submit" className="submit-button">
          Submit
        </Button>
       
      </Form>
    </div>
        </div>


        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0', paddingTop: "60px" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.1700115465187!2d77.58315097588776!3d13.024843213707086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17b80e3a48b9%3A0x67788c7339ead2d1!2s309%2C%201st%20Main%20Rd%2C%20Ganga%20Nagar%20Layout%2C%20Ganganagar%2C%20Bengaluru%2C%20Karnataka%20560032!5e0!3m2!1sen!2sin!4v1730807895804!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

       
      <footer className="footer bg-dark text-white">
            <div className="cust-container">
                <div className="row">
                {/* Footer Bottom */}
                <div className="footer-bottom mt-4 text-center">
                    <p>© 2024 <span className="text-warning">Converging Miinds Management Pvt Ltd.</span> All rights reserved. Designed & Developed by <a href='https://www.digiworq.com/' target='_blank' rel="noreferrer"><span className="text-warning">DIGIWORQ</span></a></p>
                </div>
            </div>
            </div>
        </footer>

    </div>
  )
}

export default Career