import React from 'react';
import './Services.css';
import OfferCarousel from '../../Components/OwlCarousel/Home2'
import SpotlightCarousel from '../../Components/OwlCarousel/Spotlight'
import simplify from '../../Assets/simplify.png'
import simplify1 from '../../Assets/simplify2.png'
import simplify2 from '../../Assets/simplify3.png'
import simplify3 from '../../Assets/simplify4.png'
import simplify4 from '../../Assets/simplify5.png'
import simplify5 from '../../Assets/simplify6.png'
import empower1 from '../../Assets/empower1.png'
import empower2 from '../../Assets/empower2.png'
import empower3 from '../../Assets/empower3.png'
// import FaqSection from '../Faq/Faq';
import { Helmet } from 'react-helmet';

const Services = () => {
  return (
    <div>
     <Helmet>
        <title>Converging Miinds</title>
        <meta name="Best Audit and Accounting Consultancy Services" content="Get Tailoured Financial Statements, Consulting, Accounting Consultancy, and Auditing Services In Bangalore. Enquiry Now with Our Team of Experts Today!" />
        <meta name="keywords" content="accounting consultancy, auditing services, tax compliance, financial statements, finance management" />
      </Helmet>
         {/* <div className="services-banner">
            <div className="banner-content cust-container">
              <p className='sub-banner-htag services'>Your Trusted Partner for 
              <br /><span className='highlight'>Finance Management and Growth!</span></p>
            </div>
        </div> */}

        <div className='cust-container' style={{paddingTop: "60px"}}>
          <h1 className='htag' style={{textAlign: "center"}}>Services Spotlight:<span className='fw-bold text-dark'>Tailoured Audit and Accounting Consultancy Services</span></h1>
          <p style={{textAlign: "center"}}>At Converging Miinds, we provide comprehensive accounting consultancy services designed to empower your business. From expert financial consulting and seamless accounting outsourcing to meticulous 
          auditing services and tax compliance, our dedicated team is committed to navigating your financial landscape with precision and integrity.</p>
          <OfferCarousel/>
        </div>

        <div className='cust-container' style={{paddingTop: "120px"}}>
          <h2 className='htag' style={{textAlign: "center"}}>Dedicated Financial Statement Preparation and 
          <span className='fw-bold text-dark'> Seamless Accounting Outsourcing Services </span></h2> <br/><br/>
          <SpotlightCarousel/>
        </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
          <h3 className='htag' style={{textAlign: "center"}}>Top 6 Reasons Why You Should Choose Our Best <span className='fw-bold text-dark'>Auditing and Financial Consulting Services</span></h3>
          <div className='row mt-5'>
              <div className='col-sm-12 col-md-4'>
                  <div>
                    <img loading="lazy" src={simplify} alt='simplify'></img>
                    <p className='h3'><b>Expert <br/> Professionals</b></p>
                    <p>Our team features highly qualified experts in auditing, accounting, and tax compliance, ensuring that you receive knowledgeable guidance tailoured to your unique business challenges and objectives.</p>
                  </div>
              </div>

              <div className='col-12 col-md-4'>
                  <div>
                    <img loading="lazy" src={simplify1} alt='simplify'></img>
                    <p className='h3'><b>Customised Service <br/> Offerings</b></p>
                    <p>We recognize that no two businesses are alike. That's why our services are customised to meet your specific needs, allowing you to benefit from solutions that directly align with your operational goals and industry standards.
</p>
                  </div>
              </div>
              <div className='col-12 col-md-4'>
                  <div>
                    <img loading="lazy" src={simplify2} alt='simplify'></img>
                    <p className='h3'><b>Assurance of 
                    <br/> Compliance</b></p>
                    <p>With our thorough understanding of the regulatory environment, we help safeguard your business against potential compliance issues. 
                    Our services ensure that your financial practices align with current laws and regulations, mitigating risks.</p>
                  </div>
              </div>
          </div>

          <div className='row mt-5'>
              <div className='col-12 col-md-4'>
                  <div>
                    <img loading="lazy" src={simplify3} alt='simplify'></img>
                    <p className='h3'><b>Focus on Your <br/> Core Business</b></p>
                    <p>By outsourcing your financial management tasks to us, you can dedicate more time and energy to what truly matters: growing your business. We enhance your operational efficiency, allowing you to focus on strategic priorities.</p>
                  </div>
              </div>

              <div className='col-12 col-md-4'>
                  <div>
                    <img loading="lazy" src={simplify4} alt='simplify'></img>
                    <p className='h3'><b>Transparent Financial <br/> Reporting</b></p>
                    <p>We provide clear, reliable financial reports that empower you to make informed business decisions. Our meticulous attention to detail ensures accuracy, helping you gain valuable insights into your financial health.</p>
                  </div>
              </div>

              <div className='col-12 col-md-4'>
                  <div>
                    <img loading="lazy" src={simplify5} alt='simplify'></img>
                    <p className='h3'><b>Commitment <br/> to Innovation</b></p>
                    <p>Staying updated with the latest trends and technologies is part of our ethos. Our commitment to continuous improvement ensures that we provide you with innovative solutions that enhance your financial management practices.</p>
                  </div>
              </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "60px", paddingBottom: "60px"}}>
          <div className='row mt-5'>
              <div className='col-12 col-md-6'>
                  <h4 className='htag'>How are we Different in 
                  <br/>
                  <span className='fw-bold text-dark'>Financial Management <br/> for Companies?</span></h4>
                  <p>In a world where financial clarity is essential for business success, choosing the right accounting partner can make all the difference.</p>
              </div>

              <div className='col-12 col-md-6'>
                  <div className='d-flex'>
                    <img loading="lazy" src={empower1} alt='simplify' className='empower'></img>
                    <p className='h3'><b>Communication Excellence</b></p>
                  </div>
                  <p className='mx-5'>We translate complex financial data into clear insights, empowering you to make informed strategic decisions. Our tailoured reports ensure that all stakeholders understand your financial position.
</p>

                  <div className='d-flex'>
                    <img loading="lazy" src={empower2} alt='simplify' className='empower'></img>
                    <p className='h3'><b>Regulatory Compliance</b></p>
                  </div>
                  <p className='mx-5'>Navigating the complexities of finance management can be daunting. We ensure your financial practices comply with the latest standards, protecting your business and enhancing credibility.</p>

                  <div className='d-flex'>
                    <img loading="lazy" src={empower3} alt='simplify' className='empower'></img>
                    <p className='h3'><b>Ethical Integrity
</b></p>
                  </div>
                  <p className='mx-5'>Our dedication to upholding the highest ethical standards fosters trust and transparency in all our interactions, leading most clients to choose us for a long-term commitment.
</p>
              </div>
          </div>
          </div>

          {/* <div className='cust-container' style={{paddingTop: "120px"}}>
            <h2 className='htag'>Frequently Asked Questions</h2>
            <p>At eu lobortis pretium tincidunt amet lacus ut aenean aliquet</p>
              <FaqSection/>
          </div> */}

          <footer className="footer bg-dark text-white">
            <div className="cust-container">
                <div className="row">
                {/* Footer Bottom */}
                <div className="footer-bottom mt-4 text-center">
                    <p>© 2024 <span className="text-warning">Converging Miinds Management Pvt Ltd.</span> All rights reserved. Designed & Developed by <a href='https://www.digiworq.com/' target='_blank' rel="noreferrer"><span className="text-warning">DIGIWORQ</span></a></p>
                </div>
            </div>
            </div>
        </footer>
    </div>
  )
}

export default Services