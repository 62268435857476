import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import offering1 from '../../Assets/offering1.png'
import offering2 from '../../Assets/offering2.png'
import offering3 from '../../Assets/offering3.png'
import offering4 from '../../Assets/offering4.png'
import offering5 from '../../Assets/offering5.png'
import offering6 from '../../Assets/offering6.png'
import offering7 from '../../Assets/offering7.png'
import offering8 from '../../Assets/offering8.png'
import offering9 from '../../Assets/offering9.png'
import './Home2.css'

function CarouselComponent() {
  const settings = {
    dots: true,                  // Enables dots
    infinite: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 350,
    autoplay: false,             // Enables autoplay
    autoplaySpeed: 3000,
    arrows: true,                // Enables navigation arrows
    responsive: [
      {
        breakpoint: 1399,        // Screens smaller than 1200px
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,        // Screens smaller than 992px (tablets)
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,      // Center the item
          centerPadding: '20px', // Optional: add padding to the center item
        }
      },
      {
        breakpoint: 768,         // Screens smaller than 768px (large phones)
        settings: {
          slidesToShow: 2,       // Show two items at a time
          centerMode: true,      // Center the item
          centerPadding: '20px', // Optional: add padding to the center item
        }
      },
      {
        breakpoint: 576,         // Screens smaller than 576px (small phones)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  

  return (
    <Slider {...settings} id="main-servicess">
      <div className="offering">
        <img loading="lazy" src={offering1} alt="fuel1"></img>
        <div className="overlay">
            <h3 className="overlay-title">Incorporation and Startup</h3>
            {/* <p className="overlay-subtitle">CMiinds has seamlessly integrated technology </p> */}
            <a href="/Services/Incorporation-and-Startup-Support"><button className="overlay-button">Learn more</button></a> 
        </div>
      </div>
      <div className="offering">
        <img loading="lazy" src={offering2} alt="fuel2"></img>
        <div className="overlay">
            <h3 className="overlay-title">BPO</h3>
            {/* <p className="overlay-subtitle">CMiinds has seamlessly integrated technology</p> */}
            <a href="/Services/BPO"><button className="overlay-button">Learn more</button></a> 
        </div>
      </div>
      <div className="offering">
        <img loading="lazy" src={offering3} alt="fuel3"></img>
        <div className="overlay">
            <h3 className="overlay-title">Audit</h3>
            {/* <p className="overlay-subtitle">CMiinds has seamlessly integrated technology</p> */}
            <a href="/Services/Audit"><button className="overlay-button">Learn more</button></a> 
        </div>
    </div>
    <div className="offering">
        <img loading="lazy" src={offering4} alt="fuel1"></img>
        <div className="overlay">
            <h3 className="overlay-title">Payroll Processing</h3>
            {/* <p className="overlay-subtitle">CMiinds has seamlessly integrated technology</p> */}
            <a href="/Services/Payroll-Processing"><button className="overlay-button">Learn more</button></a> 
        </div>
      </div>
      <div className="offering">
        <img loading="lazy" src={offering5} alt="fuel1"></img>
        <div className="overlay">
            <h3 className="overlay-title">Direct Taxation</h3>
            {/* <p className="overlay-subtitle">CMiinds has seamlessly integrated technology</p> */}
            <a href="/Services/Direct-Taxation"><button className="overlay-button">Learn more</button></a> 
        </div>
      </div>
      <div className="offering">
        <img loading="lazy" src={offering6} alt="fuel1"></img>
        <div className="overlay">
            <h3 className="overlay-title">Indirect Tax</h3>
            {/* <p className="overlay-subtitle">CMiinds has seamlessly integrated technology</p> */}
            <a href="/Services/Indirect-Tax"><button className="overlay-button">Learn more</button></a> 
        </div>
      </div>
      <div className="offering">
        <img loading="lazy" src={offering7} alt="fuel1"></img>
        <div className="overlay">
            <h3 className="overlay-title">Regulatory</h3>
            {/* <p className="overlay-subtitle">CMiinds has seamlessly integrated technology</p> */}
            <a href="/Services/Regulatory"><button className="overlay-button">Learn more</button></a> 
        </div>
      </div>
      <div className="offering">
        <img loading="lazy" src={offering8} alt="fuel1"></img>
        <div className="overlay">
            <h3 className="overlay-title">CFO Outsourcing Business Finance Consulting</h3>
            {/* <p className="overlay-subtitle">CMiinds has seamlessly integrated technology</p> */}
            <a href="/Services/CFO-Outsourcing-Business-Finance-Consulting"><button className="overlay-button">Learn more</button></a> 
        </div>
      </div>
      <div className="offering">
        <img loading="lazy" src={offering9} alt="fuel1"></img>
        <div className="overlay">
            <h3 className="overlay-title">Corporate Finance</h3>
            {/* <p className="overlay-subtitle">CMiinds has seamlessly integrated technology</p> */}
            <a href="/Services/Corporate-Finance"><button className="overlay-button">Learn more</button></a> 
        </div>
      </div>
    </Slider>
  );
}

export default CarouselComponent;
