import React, { useState } from "react";
import "./Service7.css";
import { Helmet } from "react-helmet";

const Service1 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Corporate Law Compliance and Advisory",
      content: (
        <>
          <p>
            Our Corporate Law division specializes in providing comprehensive
            advisory services to ensure your business meets all legal
            requirements. We focus on the following key areas:
          </p>
          <ul className="service1-list">
            <li>
              <span className="bold">Company Law Advisory/Compliances:</span>
              <br />
              ROC Needs: Understanding the requirements of the Registrar of
              Companies (ROC) is crucial for maintaining compliance with the
              Companies Act. Our team assists businesses in fulfilling these
              requirements, which include registration, filing annual returns,
              and maintaining statutory records. Key services include:
              <ul className="service-list">
                <li>
                  <span className="bold1">Incorporation Services</span>
                </li>

                <li>
                  <span className="bold1">Statutory Compliances</span>
                </li>

                <li>
                  <span className="bold1">Secretarial Services and maintenance of registers</span>
                </li>

                <li>
                  <span className="bold1">Annual Filing and Reporting</span>
                </li>

                <li>
                  <span className="bold1">Dematerialization of shares services</span>
                </li>
              </ul>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Foreign Investment Promotion Board (FIPB) and STPI Services",
      content: (
        <>
          <p>
          Navigating the landscape of foreign investments in India requires expert guidance. Our services related to the Foreign Investment Promotion Board (FIPB) and Software Technology Park of India (STPI) include:
          </p>

          <ul className="service-list">
            <li>
              <span >FIPB Advisory</span>
            </li>
            <li>
              <span>Documentation and Filing</span>
            </li>
            <li>
              <span >STPI Registration</span>
            </li>

            <li>
              <span>
                Compliance Management for STPI Units
              </span>
            </li>

            <li>
              <span >Taxation Advisory</span>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Foreign Exchange Management Act (FEMA)",
      content: (
        <>
          <p>
            The Foreign Exchange Management Act (FEMA) governs foreign exchange
            transactions in India, and compliance with its provisions is
            essential for businesses engaged in international trade and
            investment. Our FEMA services include:
          </p>
          <ul className="service-list">
            <li>
              <span >Advisory on FEMA Regulations</span></li>
              <li> 
                <span >Regulatory Approvals</span>
              </li>
              <li>
                <span>FEMA Compliance</span>
              </li>
           
          </ul>
        </>
      ),
    },

    {
      title: "Special Economic Zone (SEZ) Advisory",
      content: (
        <>
          <p>
          Special Economic Zones (SEZs) provide businesses with attractive benefits, including tax incentives and simplified regulations. Our services for businesses operating or planning to operate in SEZs include:
          </p>
          <ul className="service-list">
            <li>
              <span >SEZ Registration and Compliance</span>
            </li>
            <li>
              <span >Operational Compliance</span>
            </li>

            <li>
              <span >Advisory on Incentives</span>
            </li>

            <li>
              <span>Support in Legal Matters</span>
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Converging Miinds</title>
        <meta
          name="Comprehensive Regulatory Compliance Services"
          content="Navigate corporate law and compliance with our expert services in ROC, FEMA, SEZ, FIPB, and STPI to streamline your business operations and investments."
        />
        <meta
          name="keywords"
          content="Corporate law Compliance, Regulatory compliance, Foreign Exchange Management, SEZ Act, Foreign Investment Promotion Board"
        />
      </Helmet>
      {/* <div className="services7-banner">
        <div className="banner-content cust-container">
          <h1 className="sub-banner-htag services7">
          Expert Advisory And Comprehensive 
            <br />
            <span className="highlight">Regulatory Compliance Services</span>
          </h1>
        </div>
      </div> */}

      <div className="cust-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Services">Services</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Regulatory
            </li>
          </ol>
        </nav>
      </div>


      <div className="cust-container bnr-img" style={{ paddingTop: "60px" }}>
        <img src="https://res.cloudinary.com/dvi7lnk6r/image/upload/v1731940324/07_Regulatory_wxb9aw.jpg" alt="incorporation" className="w-25 mx-auto"></img>
      </div>

      <div className="cust-container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-md-12">
            <p>
            At Cmiinds, we understand the challenges businesses face in navigating complex regulatory frameworks. Our dedicated Regulatory division offers expert guidance in corporate law compliance, foreign exchange regulations, special economic zones, and foreign investments. We ensure your organization stays compliant while unlocking its full potential. With our tailored solutions, you can focus on growing your core business while we manage the intricacies of regulatory compliance.
            </p>
          </div>
        </div>
      </div>

      <div
        className="cust-container"
        style={{ paddingTop: "60px", paddingBottom: "60px" }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="tabs-container">
              <div className="tabs-header">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`tab-button ${
                      activeTab === index ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
              <div className="tab-content">{tabs[activeTab].content}</div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer bg-dark text-white">
        <div className="cust-container">
          <div className="row">
            {/* Footer Bottom */}
            <div className="footer-bottom mt-4 text-center">
              <p>
                © 2024{" "}
                <span className="text-warning">
                  Converging Miinds Management Pvt Ltd.
                </span>{" "}
                All rights reserved. Designed & Developed by{" "}
                <a
                  href="https://www.digiworq.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-warning">DIGIWORQ</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Service1;
