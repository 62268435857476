/* eslint-disable jsx-a11y/iframe-has-title */
import React, {useState} from 'react'
import './Career.css'
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const Career = () => {

  const [selectedCategory, setSelectedCategory] = useState("ADMIN");

  const categories = [
    "ADMIN",
    "ACCOUNTANTS",
    "SUPPORT",
    "AUDITOR",
    "MARKETING",
  ];

  const jobs = [
    { title: "Chartered Accountants", experience: "2 Years", deadline: "2025-05-08", category: "ADMIN" },
    { title: "GST Specialist", experience: "1 Year", deadline: "2025-05-08", category: "ACCOUNTANTS" },
    { title: "Professional Accounting", experience: "3 Years", deadline: "2025-05-08", category: "AUDITOR" },
    { title: "Bookkeeper", experience: "2 Years", deadline: "2025-05-08", category: "SUPPORT" },
    { title: "Business Account", experience: "3 Years", deadline: "2025-05-08", category: "MARKETING" },
  ];


  // Filter logic
  const filteredJobs = jobs.filter(job => job.category === selectedCategory);


  return (
    <div>
    <Helmet>
        <title>Converging Miinds</title>
        <meta name="Accounting Careers | Growth Opportunities at Cmiinds" content="Join our dynamic team of accounting professionals. Develop your skills, work on challenging projects, and build a rewarding career in audit and financial consulting" />
      </Helmet>
        {/* <div className="career-banner">
          <div className="banner-content cust-container">
            <p className='sub-banner-htag career'>Explore 
            <br /><span className='highlight'>Our Openings</span></p>
          </div>
        </div> */}

        <div className='cust-container'>
        <div className="contact-container my-5">
      <h2 className="contact-header htag text-center">Application Form</h2>
      <p className='text-center'>Join a team that doesn't just follow trends, but creates them. <br/>
Here, your expertise is our competitive advantage, and your growth is our priority
</p>
      <Form className="contact-form">
        <Row>
          <Col md={6}>
         
            <Form.Group className="mb-3" controlId="formName">
              <Form.Control type="text" placeholder="Your Name*" />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3" controlId="formContactName">
              <Form.Control type="text" placeholder="Your Location*"/>
            </Form.Group>
          </Col>
        </Row>
       
        <Row>
          <Col md={6}>
         
            <Form.Group className="mb-3" controlId="formName">
              <Form.Control type="text" placeholder="Your Email*" />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3" controlId="formContactName">
              <Form.Control type="text" placeholder="Your Phone No.*"/>
            </Form.Group>
          </Col>
        </Row>
        <Row>

          <Col md={6}>
            <Form.Group className="mb-3" controlId="formContactName">
              <Form.Control type="text" placeholder="Your Experience*" />
            </Form.Group>
          </Col>
          <Col md={6}>
         
            <Form.Group className="mb-3" controlId="formName">
              <Form.Control type="file" placeholder="Upload you resume" />
            </Form.Group>
          </Col>

         
        </Row>
          <Row>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="formMessage">
            <Form.Control as="textarea" rows={3}  placeholder="Write Your Message*"/>
          </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="formNewsletter">
          <Form.Check type="checkbox" label="By submitting this form, you acknowledge that Nvincy may use your personal 
information for marketing communications as outlined in its privacy policy *" />
        </Form.Group>
        <Button variant="primary" type="submit" className="career-submit-button">
          Submit
        </Button>
        
      </Form>
      </div>
        </div>
        <div className='cust-container' style={{paddingTop: "30px"}}>
          <h3 className='htag text-center'>Career Openings</h3>
          <p className='text-center'>We’re always looking for creative, talented self-starters to join the Cmiinds
          family. <br/> Check out our open roles below and fill out an application.</p>
          <div className="careers-container">
      <div className="categories">
        {categories.map((category) => (
          <div
            key={category}
            onClick={() => setSelectedCategory(category.split(" ")[0])}
            className={selectedCategory === category.split(" ")[0] ? "category selected" : "category"}
          >
            {category}
          </div>
        ))}
      </div>
      <div className="job-list">
        {filteredJobs.map((job, index) => (
          <div key={index} className="job">
            <p className='h3 job-title'>{job.title}</p>
            <p className='exp'><span className='headi'>Experience:</span> {job.experience}</p>
            <p className='exp'><span className='headi'>Deadline:</span> {job.deadline}</p>
          </div>
        ))}
      </div>
    </div>
        </div>

        <footer className="footer bg-dark text-white">
            <div className="cust-container">
                <div className="row">
                {/* Footer Bottom */}
                <div className="footer-bottom mt-4 text-center">
                    <p>© 2024 <span className="text-warning">Converging Miinds Management Pvt Ltd.</span> All rights reserved. Designed & Developed by <a href='https://www.digiworq.com/' target='_blank' rel="noreferrer"><span className="text-warning">DIGIWORQ</span></a></p>
                </div>
            </div>
            </div>
        </footer>


    </div>
  )
}

export default Career