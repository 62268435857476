import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // Import custom CSS for styling
import logo from '../../Assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons';


const CustomNavbar = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = (e) => {
        e.preventDefault();
        setDropdownVisible(!dropdownVisible);
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
  
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const toggleDropdown = () => {
    //   setDropdownOpen(!dropdownOpen);
    // };
  
    const [openDropdown, setOpenDropdown] = useState(null);
  
  const toggleDrop = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };
  return (
    <div>
        
        <header className="navbar-container">
            <div className="navbar-content">
                {/* Logo Section */}
                <div className="logo-container" id="lc">
                    <img loading="lazy" src={logo} alt="Converging Miinds Logo" className="logo" />
                </div>

                {/* Navigation Links */}
                <nav className="navbar-links">
                    <NavLink exact to="/" activeClassName="active" className="nav-link">
                        Home
                    </NavLink>
                    <NavLink to="/About" activeClassName="active" className="nav-link">
                        About Us
                    </NavLink>
                    <li
            className="nav-item services"
            onMouseEnter={() => window.innerWidth > 768 && setDropdownVisible(true)}
            onMouseLeave={() => window.innerWidth > 768 && setDropdownVisible(false)}
        >
            <div className="nav-link services">
            <NavLink
    to="/Services"
    className="services-link"
    onClick={(e) => {
        if (window.innerWidth < 768) {
            if (!dropdownVisible) {
                setDropdownVisible(true); // Show the dropdown
            } else {
                setDropdownVisible(false); // Hide the dropdown when navigating
            }
        }
    }}
>
    Services
</NavLink>
<span
    className="dropdown-icon"
    onClick={(e) => toggleDropdown(e)}
>
    &#9662;
</span>
            </div>
            {dropdownVisible && (
                <ul className="dropdown"  onClick={(e) => toggleDropdown(e)}>
                    <li>
                        <NavLink to="/Services/Incorporation-and-Startup-Support" className="dropdown-item">
                            Incorporation and Startup Support
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/Services/BPO" className="dropdown-item">
                            BPO
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/Services/Audit" className="dropdown-item">
                            Audit
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/Services/Payroll-Processing" className="dropdown-item">
                            Payroll Processing
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/Services/Direct-Taxation" className="dropdown-item">
                            Direct Taxation
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/Services/Indirect-Tax" className="dropdown-item">
                            Indirect Tax
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/Services/Regulatory" className="dropdown-item">
                            Regulatory
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/Services/CFO-Outsourcing-Business-Finance-Consulting" className="dropdown-item">
                            CFO Outsourcing/Business Finance Consulting
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/Services/Corporate-Finance" className="dropdown-item">
                            Corporate Finance
                        </NavLink>
                    </li>
                </ul>
            )}
        </li>
                    <NavLink to="/KnowHow" activeClassName="active" className="nav-link">
                        Know How
                    </NavLink>
                    <NavLink to="/Career" activeClassName="active" className="nav-link">
                        Careers
                    </NavLink>
                </nav>

                <div className='contact'>
                    <NavLink to="/Contact" activeClassName="active" className="nav-link">
                        <button className='button'>Contact Us</button>
                    </NavLink>
                </div>
            </div>
        </header>


        {/* Mobile menu */}

        <nav className="navbar-mobile d-flex d-md-none">
        <div className="cust-mobile-container d-flex w-100 justify-content-between align-items-center">
          {/* Navbar Brand (Logo) inside mobile menu */}
          <a className="navbar-brand" href="/">
            <img loading="lazy" src={logo} alt="Logo" className='mobile-logo' />
          </a>

          {/* Toggle Button for Mobile Screens */}
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
          </button>
        </div>

        {/* Collapsible Mobile Menu */}
              {isMenuOpen && (
          <div className="mobile-menu">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="navlinkstyles" to="/" onClick={toggleMenu}>Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="navlinkstyles" to="/About" onClick={toggleMenu}>About</NavLink>
              </li>
              
              {/* Products Dropdown */}
              <li className="nav-item dropdown">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <NavLink
                    className="dropdown-item"
                    to="/Services"
                    style={{ fontSize: "18px", marginLeft: "0", padding: "0 0 0 0" }}
                    onClick={toggleMenu}
                  >
                    Services
                    <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#0E0E0E"
                    style={{ cursor: "pointer", marginLeft: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleDrop(0); // Index for Products dropdown
                    }}
            />
                  </NavLink>
             
          </div>
          <div className={`dropdown-menu ${openDropdown === 0 ? 'show' : ''}`}>
            <NavLink className="dropdown-item" to="/Services/Incorporation-and-Startup-Support" style={{ fontSize: "18px" }} onClick={toggleMenu}> Incorporation and Startup <br/> Support</NavLink>
            <NavLink className="dropdown-item" to="/Services/BPO" style={{ fontSize: "18px" }} onClick={toggleMenu}>BPO</NavLink>
            <NavLink className="dropdown-item" to="/Services/Audit" style={{ fontSize: "18px" }} onClick={toggleMenu}>Audit</NavLink>
            <NavLink className="dropdown-item" to="/Services/Payroll-Processing" style={{ fontSize: "18px" }} onClick={toggleMenu}>Payroll Processing</NavLink>
            <NavLink className="dropdown-item" to="/Services/Direct-Taxation" style={{ fontSize: "18px" }} onClick={toggleMenu}>Direct Taxation</NavLink>
            <NavLink className="dropdown-item" to="/Services/Indirect-Tax" style={{ fontSize: "18px" }} onClick={toggleMenu}>Indirect-Tax</NavLink>
            <NavLink className="dropdown-item" to="/Services/Regulatory" style={{ fontSize: "18px" }} onClick={toggleMenu}>Regulatory</NavLink>
            <NavLink className="dropdown-item" to="/Services/CFO-Outsourcing-Business-Finance-Consulting" style={{ fontSize: "18px" }} onClick={toggleMenu}>CFO Outsourcing/Business <br/> Finance Consulting
</NavLink>
            <NavLink className="dropdown-item" to="/Services/Corporate-Finance" style={{ fontSize: "18px" }} onClick={toggleMenu}>Corporate Finance</NavLink>
          </div>
        </li>

        {/* Other menu items */}
        <li className="nav-item">
          <NavLink className="navlinkstyles" to="/Career" onClick={toggleMenu}>Career</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="navlinkstyles button_1" to="/Contact" onClick={toggleMenu}>Contact</NavLink>
        </li>
      </ul>
    </div>
  )}
      </nav>








    </div>





   
  );
};

export default CustomNavbar;
