import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import Spotlight1 from '../../Assets/spotlight.svg';
import Spot4 from '../../Assets/spotlights/01_Accounting-&-Bookkeeping.png';
import Spot5 from '../../Assets/spotlights/02_Financial-Statement-Preparation.png';
import Spot7 from '../../Assets/spotlights/03_TDS-&-TCS-Compliance.png';
import Spot9 from '../../Assets/spotlights/04_Payroll-Processing.png';
import Spot10 from '../../Assets/spotlights/05_Income-Tax-Filing.png';
import Spot6 from '../../Assets/spotlights/spot1.png';
import Spot12 from '../../Assets/spotlights/07_Transfer-Pricing-services.png';
import Spot13 from '../../Assets/spotlights/08_Indirect-Tax-Compliance.png';
import Spot14 from '../../Assets/spotlights/09_Financial-Consulting.png';
import Spot15 from '../../Assets/spotlights/10_Management-Accounting.png';
import Spot16 from '../../Assets/spotlights/11_Company-Registration-&-Compliance.png';
import Spot17 from '../../Assets/spotlights/12_Regulatory-Compliance.png';
import Spot18 from '../../Assets/spotlights/13_BPO.png';
import Spot19 from '../../Assets/spotlights/14_Financial-modelling.png';
import Spot20 from '../../Assets/spotlights/15_Direct-Taxation.png';
import Spot21 from '../../Assets/spotlights/16_Incorporation and Start up Support.png';
import Spot22 from '../../Assets/spotlights/17_CFO-Outsourcing-Business-Finance-Consulting.png';
import Spot23 from '../../Assets/spotlights/18_Corporate.png';
import Spot11 from '../../Assets/spotlights/06_Tax-Planning.png';


import './Spotlight.css'
import React, { useState } from 'react';

function CarouselComponent() {
  const settings = {
    dots: true,                  // Enables dots
    infinite: false,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 1,
    afterChange: (index) => setActiveIndex(index),
    margin: 350,
    autoplay: false,             // Enables autoplay
    autoplaySpeed: 3000,
    arrows: true,                // Enables navigation arrows
    responsive: [
      {
        breakpoint: 1399,        // Screens smaller than 1200px
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,        // Screens smaller than 992px (tablets)
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,      // Center the item
          centerPadding: '20px', // Optional: add padding to the center item
        }
      },
      {
        breakpoint: 768,         // Screens smaller than 768px (large phones)
        settings: {
          slidesToShow: 2,       // Show two items at a time
          centerMode: true,      // Center the item
          centerPadding: '20px', // Optional: add padding to the center item
        }
      },
      {
        breakpoint: 576,         // Screens smaller than 576px (small phones)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  

  const [activeIndex, setActiveIndex] = useState(0);

  const spotlightData = [
    // {
    //     title: "Auditing Services",
    //     description: "Our auditing services provide a thorough examination of financial statements to ensure accuracy and compliance with applicable accounting standards. By conducting independent assessments, we identify areas of risk, enhance transparency, and build stakeholder confidence, ultimately supporting sound financial management.",
    //     imgSrc: Spotlight1, 
    //     contentImgSrc: Spot1, 
    //   },
    //   {
    //     title: "Accounting Outsourcing Service",
    //     description: "With our accounting outsourcing service, companies can delegate their financial operations to our expert team, allowing them to focus on core business functions. We handle tasks such as bookkeeping, financial statement preparation, and tax compliance, providing cost-effective solutions for effective financial management.",
    //     imgSrc: Spotlight1, 
    //     contentImgSrc: Spot2, 
    //   },
      {
        title: "Regulatory Compliance",
        description: "Navigating the complex landscape of regulatory requirements can be daunting. Our regulatory compliance services ensure that your financial practices adhere to local and international laws. We offer comprehensive support in preparing accurate financial statements and meeting all necessary compliance obligations.",
        contentImgSrc: Spot17, // Content section image
      },
      {
        title: "Accounting & Bookkeeping",
        description: "Our accounting and bookkeeping services guarantee that your financial records are meticulously maintained and up-to-date. We offer tailoured solutions to help businesses manage their day-to-day financial transactions while preparing detailed financial statements for informed decision-making and strategic planning.",
        contentImgSrc: Spot4, // Content section image
      },
      {
        title: "Financial Statement Preparation",
        description: "We specialise in financial statement preparation, delivering accurate and timely reports that reflect your company’s financial position. Our team ensures compliance with accounting standards, enabling stakeholders to assess the financial health of your organisation confidently.",
        contentImgSrc: Spot5, // Content section image
      },
      {
        title: "GST Compliance",
        description: "Navigating Goods and Services Tax (GST) can be challenging. Our GST compliance services provide thorough support in ensuring adherence to all GST regulations, filing returns accurately, and preparing financial statements that reflect GST impacts, helping you avoid penalties and streamline tax management.",
        contentImgSrc: Spot6, // Content section image
      },
      {
        title: "TDS & TCS Compliance",
        description: "We assist businesses with Tax Deducted at Source (TDS) and Tax Collected at Source (TCS) compliance, ensuring timely adherence to regulations and accurate reporting. Our expert guidance helps you manage cash flows effectively while minimising the risks associated with non-compliance.",
        contentImgSrc: Spot7, // Content section image
      },
      {
        title: "Payroll Processing",
        description: "Our payroll processing service streamlines the calculation and distribution of employee wages, ensuring compliance with labour laws and tax regulations. We provide detailed payroll reports to support effective financial management and maintain compliance with statutory obligations.",
        contentImgSrc: Spot9, // Content section image
      },
      {
        title: "Income Tax Filing",
        description: "We offer comprehensive income tax filing services to ensure compliance with the latest regulations while maximising tax efficiency. Our experienced professionals assist with preparation and submission of accurate tax returns, minimising your tax liabilities and enhancing financial management.",
        contentImgSrc: Spot10, // Content section image
      },
      {
        title: "Tax Planning & Advisory",
        description: "Our tax planning and advisory services help businesses develop strategic tax strategies that align with their financial goals. We analyse current tax positions and recommend solutions that optimise tax compliance while supporting long-term financial management.",
        contentImgSrc: Spot11, // Content section image
      },

      {
        title: "Transfer Pricing Services",
        description: "We provide specialised transfer pricing services that ensure compliance with international regulations while safeguarding your organisation from potential disputes. Our experts develop and implement robust transfer pricing strategies, helping you maintain accurate financial statements and tax compliance.",
        contentImgSrc: Spot12, // Content section image
      },

      {
        title: "Indirect Tax Compliance",
        description: "Our indirect tax compliance services ensure your organisation adheres to tax regulations like VAT, sales tax, and service tax. We help prepare necessary documentation and file returns accurately, ensuring that financial management practices are sound and compliant.",
        contentImgSrc: Spot13, // Content section image
      },

      {
        title: "Financial Consulting",
        description: "Our financial consulting services empower businesses with strategic insights into financial management, helping them make informed decisions. We analyse financial statements, assess risks, and develop action plans that align with organisational objectives and growth strategies.",
        contentImgSrc: Spot14, // Content section image
      },

      {
        title: "Management Accounting",
        description: "We offer management accounting services that provide crucial information for internal stakeholders. Our approach focuses on financial data analysis and reporting, enabling effective budgeting, forecasting, and performance evaluation for improved decision-making.",
        contentImgSrc: Spot15, // Content section image
      },

      {
        title: "Company Registration & Compliance",
        description: "Our company registration and compliance services simplify the process of establishing your business. We guide you through legal requirements, ensuring all regulatory processes are completed accurately and efficiently, allowing you to focus on your core operations.",
        contentImgSrc: Spot16, // Content section image
      },
      {
        title: "Regulatory Compliance Beyond Core Areas",
        description: "Understanding that businesses face diverse regulatory challenges, we offer services that extend beyond traditional compliance areas. Our team assists with financial management practices that address emerging regulations and industry-specific requirements, ensuring overall compliance and sustainability.",
        contentImgSrc: Spot17, // Content section image
      },
      {
        title: "Business Process Outsourcing (BPO)",
        description: "Our BPO services enable your business to outsource non-core functions, optimising efficiency and reducing costs. We provide tailoured solutions for bookkeeping, payroll, and accounting, enhancing financial management while allowing you to focus on strategic initiatives.",
        contentImgSrc: Spot18, // Content section image
      },
      {
        title: "Financial Modeling and Forecasting",
        description: "We specialise in financial modelling and forecasting to provide businesses with insights into future performance. Our advanced analysis helps organisations predict trends, set budgets, and make informed decisions based on detailed financial statement projections.",
        contentImgSrc: Spot19, // Content section image
      },
      {
        title: "Direct Taxation",
        description: "Our direct taxation services ensure compliance with all applicable tax laws and regulations while helping businesses navigate complex tax landscapes. We provide thorough assessments and strategic planning to minimise tax liabilities and ensure accurate tax compliance.",
        contentImgSrc: Spot20, // Content section image
      },
      {
        title: "Incorporation and Startup Support",
        description: "We offer incorporation and startup support services designed for entrepreneurs launching new ventures. Our expertise in regulatory compliance and financial management assists you in successfully navigating the complexities of establishing a business, allowing you to focus on growth.",
        contentImgSrc: Spot21, // Content section image
      },
      {
        title: "CFO Outsourcing/Business Finance Consulting",
        description: "Our CFO outsourcing and business finance consulting services provide strategic financial leadership without the overhead of a full-time executive. We offer insights into financial management, performance analysis, and strategic planning, guiding your business toward sustainable growth.",
        contentImgSrc: Spot22, // Content section image
      },
      {
        title: "Corporate Governance Advisory",
        description: "Our corporate governance advisory services help organisations implement strong governance frameworks. We provide guidance on best practices, tax compliance with regulations, and the establishment of accountability structures, ensuring sound finance management and transparency.",
        contentImgSrc: Spot23, // Content section image
      },
      // {
      //   title: "Corporate Finance",
      //   description: "Our Corporate Finance services are designed to support businesses in managing their financial resources effectively to achieve strategic objectives. We understand that the financial landscape can be challenging, and our comprehensive suite of services equips you with the knowledge and tools necessary to navigate it successfully. Our Corporate Finance services are designed to enhance your strategic decision-making and drive sustainable growth.",
      //   imgSrc: Spotlight1,
      //   contentImgSrc: Spot24, 
      // },

  ];

  return (
    <div>
  <Slider {...settings} className="spotlightt">
    {spotlightData.map((item, index) => (
      <div
        key={index}
        className={`spotlight ${index === activeIndex ? "active" : ""}`}
        onClick={() => setActiveIndex(index)}
      >
        {/* <img loading="lazy" src={item.imgSrc} alt={item.title} className="mt-5" />  */}
        <h3 className="htag">{item.title}</h3>
      </div>
    ))}
  </Slider>

  <div className="content-section mt-5 mb-5">
    <div className="row">
        <div className="col-md-3">
        <img loading="lazy"  src={spotlightData[activeIndex].contentImgSrc} alt={spotlightData[activeIndex].title} /> {/* Content section image */}
        </div>

        <div className="col-md-9">
        <p>{spotlightData[activeIndex].description}</p>
        </div>
    </div>
    
   
  </div>
</div>

  );
}

export default CarouselComponent;
