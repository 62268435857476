import React, { useState } from "react";
import "./Service5.css";
import { Helmet } from "react-helmet";

const Service1 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Entry Strategy for Tax Efficiency",
      content: (
        <>
      <p>Strategic planning at the outset is crucial when entering new markets or launching new business initiatives. Our entry strategy services focus on creating a framework that maximizes tax efficiency right from the start. Key components include:</p>

          <ul className="service-list">
            <li>
              <span>Market Analysis</span></li>
              <li>
                <span >Legal Structure Optimization</span>
              </li>
              <li>
                <span>Choosing the Right Jurisdiction</span>
              </li>
              <li>
                <span >Risk Assessment</span>
              </li>
          </ul>
        </>
      ),
    },
    {
      title: "Corporate Tax Advisory Services",
      content: (
        <>
          <p>
          Navigating the complexities of corporate taxation can be challenging. Our corporate tax advisory services deliver expert guidance across tax planning, compliance, and strategic decision-making. Our services include:
          </p>
          <ul className="service-list">
            <li>
              <span >Tax Planning</span></li>
              <li>
                <span >Maximizing Deductions and Credits</span>
              </li>
              <li>
                <span >Regulatory Insights</span>
              </li>
              <li>
                <span >Scenario Analysis</span>
              </li>
              <li>
                <span >Providing tax updates</span>
              </li>
            
          </ul>
        </>
      ),
    },
    {
      title: "Executive Services for Tax Optimization",
      content: (
        <>
          <p>
            Cmiinds offers specialized executive services designed to assist
            high-net-worth individuals and corporate executives in managing
            their direct taxation obligations. Key features include:
          </p>
          <ul className="service-list">
            <li>
              <span>Personal Tax Planning</span>
            </li>

            <li>
              <span >Wealth Management and Structuring</span>
            </li>

            <li>
              <span>Tax Compliance Monitoring</span>
            </li>

            <li>
              <span >Advisory on International Taxation</span>
            </li>
          </ul>
        </>
      ),
    },

    {
      title: "Corporate Tax Compliance Management",
      content: (
        <>
          <p>
            Compliance with corporate tax obligations is imperative to avoid
            penalties and maintain a good standing with tax authorities. Our
            corporate tax compliance services are designed to ensure that your
            organization adheres to all necessary regulations while minimizing
            tax liabilities. These services include:
          </p>
          <ul className="service1-list">
            <li>
              <span className="bold">Tax Return Preparation and Filing</span></li>
              <li>
                <span className="bold">Audit Support</span>
              </li>
              <li>
                <span className="bold">Ongoing Compliance Monitoring</span>
              </li>
              <li>
                <span className="bold">Payment Management</span>
              </li>
              <li>
                <span className="bold">Transfer Pricing Expertise</span>
                <br />
                When dealing with international transactions and cross-border
                activities, transfer pricing regulations become significantly
                important. Our transfer pricing services include:
                <ul className="service-list">
                  <li>
                    <span className="bold1">
                      Transfer Pricing Documentation
                    </span>
                  </li>

                  <li>
                    <span className="bold1">Policy Development</span>
                  </li>

                  <li>
                    <span className="bold1">Benchmarking Studies</span>
                  </li>

                  <li>
                    <span className="bold1">Support in Dispute Resolution</span>
                  </li>
                </ul>
              </li>
            
          </ul>
        </>
      ),
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Converging Miinds</title>
        <meta
          name="Expert Direct Taxation Services for Your Business"
          content="Navigate the complexities of direct taxation with our professional services including compliance, advisory, and transfer pricing strategies for optimal tax efficiency."
        />
        <meta
          name="keywords"
          content="Direct Taxation, Tax Efficiency, Direct Taxation, Corporate Tax Obligations, Tax Compliance"
        />
      </Helmet>

      <div className="cust-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Services">Services</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Direct Taxation
            </li>
          </ol>
        </nav>
      </div>

      <div className="cust-container bnr-img" style={{ paddingTop: "60px" }}>
        <img src="https://res.cloudinary.com/dvi7lnk6r/image/upload/v1731940324/05_Direct-Taxation_kltsb1.jpg" alt="incorporation" className="w-25 mx-auto"></img>
      </div>


      <div
        className="cust-container"
        style={{ paddingTop: "60px", paddingBottom: "60px" }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="tabs-container">
              <div className="tabs-header">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`tab-button ${
                      activeTab === index ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
              <div className="tab-content">{tabs[activeTab].content}</div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer bg-dark text-white">
        <div className="cust-container">
          <div className="row">
            {/* Footer Bottom */}
            <div className="footer-bottom mt-4 text-center">
              <p>
                © 2024{" "}
                <span className="text-warning">
                  Converging Miinds Management Pvt Ltd.
                </span>{" "}
                All rights reserved. Designed & Developed by{" "}
                <a
                  href="https://www.digiworq.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-warning">DIGIWORQ</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Service1;
