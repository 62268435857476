import React, { useState } from "react";
import "./Service3.css";
import { Helmet } from "react-helmet";

const Service1 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Internal Audit",
      content: (
        <>
          <p>
          Our internal audit services are crafted to help organizations effectively assess their internal controls, risk management practices, and governance processes. With a focus on enhancing operational efficiency, we undertake the following:
          </p>
          <ul className="service-list">
            <li>
              <span className="bold1">Risk Assessment</span>
            </li>

            <li>
              <span className="bold1">Control Testing</span>
            </li>

            <li>
              <span className="bold1">Process Improvement</span>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Cost Audit",
      content: (
        <>
          Effective cost management is crucial to maintaining profitability, and our cost audit services are here to assist you. We focus on:
          <ul className="service-list">
            <li>
              <span className="bold1">Cost Evaluation</span>
            </li>

            <li>
              <span className="bold1">Efficiency Analysis</span>
            </li>

            <li>
              <span className="bold1">Benchmarking Practices</span>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Statutory Audit",
      content: (
        <>
          <p>
            A statutory audit is essential for ensuring your organization
            complies with legislative requirements and accounting standards. Our
            team provides:
          </p>
          <ul className="service-list">
            <li>
              <span className="bold1">Thorough Examination</span>
            </li>

            <li>
              <span className="bold1">Regulatory Compliance</span>
            </li>

            <li>
              <span className="bold1">Independent Opinion</span>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Specific Area Audits",
      content: (
        <>
          <p>
          Not all audits are created equal, and our specific area audits cater to the unique needs of various business divisions. Focused audits may include:
          </p>
          <ul className="service-list">
            <li>
              <span className="bold1">Statutory and legal Compliance Audit</span>
            </li>

            <li>
              <span className="bold1">Business Operational Audit</span>
            </li>
          </ul>
        </>
      ),
    },

    {
      title: "Due Diligence Audits",
      content: (
        <>
          <p>
          Engaging in mergers, acquisitions, or partnerships can be complex. Our due diligence audit services provide the necessary insights to make informed decisions by evaluating:
          </p>
          <ul className="service-list">
            <li>
              <span className="bold1">Financial Health</span>
            </li>

            <li>
              <span className="bold1">Risk Assessment</span>
            </li>

            <li>
              <span className="bold1">Market Positioning</span>
            </li>
          </ul>
        </>
      ),
    },

    {
      title: "GST Audits",
      content: (
        <>
          <p>
          Navigating GST regulations can be challenging, but our GST audit services simplify the process. We provide:
          </p>
          <ul className="service-list">
            <li>
              <span className="bold1">Transaction Review</span>
            </li>

            <li>
              <span className="bold1">Claim Verification</span>
            </li>

            <li>
              <span className="bold1">Regulatory Insights</span>
            </li>
          </ul>
        </>
      ),
    },

    {
      title: "Tax Audits",
      content: (
        <>
          <p>
          Tax compliance is crucial for every organization, and our tax audit services provide a robust framework for ensuring accuracy and minimizing risks. Our services encompass:
          </p>
          <ul className="service-list">
            <li>
              <span className="bold1">Tax Return Review</span>
            </li>

            <li>
              <span className="bold1">Regulatory Compliance</span>
            </li>

            <li>
              <span className="bold1">Tax Planning Strategy</span>
            </li>
          </ul>
        </>
      ),
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Converging Miinds</title>
        <meta
          name="Comprehensive Audit Services for Financial Clarity"
          content="Explore our expert audit services, including internal, statutory, cost, and tax audits to ensure compliance and enhance your financial performance."
        />
        <meta
          name="keywords"
          content="Internal Audit Services, Audit services, VAT Regulation, Financial statements, Financial operations"
        />
      </Helmet>
      {/* <div className="services3-banner">
        <div className="banner-content cust-container">
          <h1 className="sub-banner-htag services3">
            Comprehensive Audit Services
            <br />
            <span className="highlight">for Financial Stability</span>
          </h1>
        </div>
      </div> */}

      <div className="cust-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Services">Services</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Audit
            </li>
          </ol>
        </nav>
      </div>

      <div className="cust-container bnr-img" style={{ paddingTop: "60px" }}>
        <img src="https://res.cloudinary.com/dvi7lnk6r/image/upload/v1731940323/03_Audit_phpa8n.jpg" alt="incorporation" className="w-25 mx-auto"></img>
      </div>

      <div className="cust-container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-md-12">
            <p>
            At Cmiinds, we recognize the critical importance of audit services in ensuring transparency, compliance, and the financial well-being of your organization. Our experienced team provides tailored audit solutions to meet your specific business needs. With our comprehensive services, you can maintain efficient financial operations while staying fully aligned with regulatory standards. 
            
            </p> <br/>
            <h2 className="htag">
              Ensure Compliance with Our Range of Audit Services (internal
              audit, cost audit, statutory audit, specific area audit, due
              diligence audit, VAT audit, Tax audit)
            </h2>
          </div>
        </div>
      </div>

      <div className="cust-container" style={{ paddingTop: "85px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="tabs-container">
              <div className="tabs-header">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`tab-button ${
                      activeTab === index ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
              <div className="tab-content">{tabs[activeTab].content}</div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer bg-dark text-white">
        <div className="cust-container">
          <div className="row">
            {/* Footer Bottom */}
            <div className="footer-bottom mt-4 text-center">
              <p>
                © 2024{" "}
                <span className="text-warning">
                  Converging Miinds Management Pvt Ltd.
                </span>{" "}
                All rights reserved. Designed & Developed by{" "}
                <a
                  href="https://www.digiworq.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-warning">DIGIWORQ</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Service1;
