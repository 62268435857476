/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import './KnowHow.css'
import blg1 from '../../Assets/blogmain1.jpg'
import blg2 from '../../Assets/blogmain2.jpg'

const Career = () => {

  const posts = [
    {
      date: "1 Dec 2024",
      title: "What are some tips for dealing with nerves when having auditors at your company?",
      description: "Audits can be a source of significant stress and anxiety for many professionals....",
      image: blg1,
      link: '/KnowHowBlog1'
    },
    {
      date: "1 Dec 2024",
      title: "Where should I start with my first auditing job?",
      description: "Do you feel overwhelmed by the complexities and challenges of the audit process?...",
      image: blg2,
      link: '/KnowHowBlog2'
    },
  ];

  return (
    <div> 
        {/* <div className="know-banner">
          <div className="banner-content cust-container">
            <p className='sub-banner-htag knowhow'>Explore 
            <br /><span className='highlight'>Our Blogs</span></p>
          </div>
        </div> */}

      <div className='cust-container' style={{paddingTop: "20px", paddingBottom: "60px"}}>
        <h2 className='htag text-center'>Recent Blog Posts</h2>
        <div className="row g-4 mt-3 mx-auto d-flex justify-content-center">
          {posts.map((post, index) => (
            <div className="col-md-6 col-lg-4" key={index}>
              <div className="card ">
                <img loading="lazy" src={post.image} className="card-img-top" alt={post.title} />
                <div className="card-body">
                  <small className="text-muted mb-5">Cmiinds • {post.date}</small>
                  <h5 className="card-title mb-3">{post.title}</h5>
                  <p className="card-text">{post.description}</p>
                  <div>
                    <a href={post.link}><button className='button'>Know More</button></a> 
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div>
      </div>

      <footer className="footer bg-dark text-white">
            <div className="cust-container">
                <div className="row">
                {/* Footer Bottom */}
                <div className="footer-bottom mt-4 text-center">
                    <p>© 2024 <span className="text-warning">Converging Miinds Management Pvt Ltd.</span> All rights reserved. Designed & Developed by <a href='https://www.digiworq.com/' target='_blank' rel="noreferrer"><span className="text-warning">DIGIWORQ</span></a></p>
                </div>
            </div>
            </div>
        </footer>

    </div>
  )
}

export default Career