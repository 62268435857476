import React from 'react';
import './About.css';
import core1 from '../../Assets/core1.png';
import core2 from '../../Assets/core2.png';
import core3 from '../../Assets/core3.png';
import core4 from '../../Assets/core4.png';
import core5 from '../../Assets/core5.png';
import abtstk from '../../Assets/aboout-stock.jpg'
// import Quote  from '../../Assets/quote.png';
// import TestiCarousel from '../../Components/OwlCarousel/Home4'
// import BlogCarousel from '../../Components/OwlCarousel/Home3'
import { Helmet } from 'react-helmet';
// import Footer from './../Footer/Footer';

const About = () => {
  return (
    <div>
     <Helmet>
     <title>Converging Miinds</title>
        <meta name="Best CFO Outsourcing Solutions & Auditing Services" content="Unlock success with expert accounting, financial consulting services, tax compliance, and CFO outsourcing. Partner with us for tailoured solutions and guidance!" />
        <meta name="keywords" content="financial consulting services, tax compliance, Expert accounting" />
      </Helmet>
      {/* <div className="banner">
        <div className="banner-content cust-container">
          <p className='sub-banner-htag about'>Your Go-To Financial Partner for Auditing 
          <br /><span className='highlight'> and Tax Compliance Services</span></p>
        </div>
      </div> */}

      <div className='cust-container' style={{paddingTop: "40px"}}>
      <div className='row'>
      <div className='col-md-4'>
            <img className='w-75' src={abtstk} alt='about'></img>
      </div>
      <div className='col-md-8'>
      <button className="section-button d-flex mr-auto">ABOUT US</button> 
            <h1 className="htag driven">Pioneering Your Path to Financial Success <span className="fw-bold text-dark">with Expert Accounting and Regulatory Compliance</span></h1>
            <p>
            At Converging Miinds, we believe that financial clarity is the foundation of business success. Founded on the principles of integrity, expertise, and innovation, we are a dedicated team of financial professionals committed to empowering individuals and businesses through Expert accounting, tax, and financial consulting services.
            </p>
      </div>
      </div>
     
          
            {/* <a href="/" className="explore-button mt-5">Explore Now</a> */}
      </div>

      <div className='cust-container' style={{paddingTop: "30px"}}>
        {/* <p className="section-button">OUR JOURNEY</p> */}
        <div className='row mt-3 d-flex justify-content-between'>
            <div className='col-sm-12 col-md-4 miss-viss me-3'>
              <h3 className='htag fw-600'>VISION</h3>
              {/* <h4 className='htag miss'>Innovating Financial Strategies with Expert CFO Outsourcing and Consulting Services</h4> */}
              <p>We envision a future where all businesses, regardless of size or industry, have access to expert financial guidance that fosters growth and operational excellence. Our goal is to become a leading provider of financial services known for our integrity, innovation, and commitment to client success.
</p>
            </div>

            <div className='col-sm-12 col-md-4 miss-viss2'>
              <h3 className='htag fw-600'>MISSION</h3>
              {/* <h4 className='htag miss'>Your Trusted Partner for Expert Audit, Accounting Outsourcing and Financial Solutions</h4> */}
              <p>Our mission is to simplify the complexities of financial management, helping our clients make informed decisions that drive growth and sustainability. We aim to be a trusted partner in navigating the financial landscape, providing tailoured solutions that align with your business objectives.
</p>
            </div>
        </div>
      </div>

     

    {/* <div className='cust-container' style={{ paddingTop: "60px" }}>
            <h2 className='htag'><span style={{fontWeight: "700"}}>Driven By Values;</span> Focused On You!</h2><br/>
            <div className="row d-flex justify-content-between">
              <div className="col-md-3 col-sm-12 why-converging">
                  <p className='h1 stat'>24+</p>
                  <p className='stat-p'>Accounting Services </p>
              </div>
              <div className="col-md-3 col-sm-12 why-converging">
                  <p className='h1 stat'>17M</p>
                  <p className='stat-p'>Financial Options </p>
              </div>
              <div className="col-md-3 col-sm-12 why-converging">
                  <p className='h1 stat'>+95%</p>
                  <p className='stat-p'>Customer Satisfaction</p>
              </div>
            </div> 
      </div>  */}


      <div className='cust-container' style={{ paddingTop: "60px" }}>
        <p className="section-button">CORE VALUES</p> 
        <h4 className='htag'>Providing Expert Financial Consulting 
        <span className='fw-bold text-dark'> and Transfer Pricing Solutions</span> </h4>
        <div className='row core mt-5'>
            <div className='col-12 col-md-3'>
              <img className='w-25' loading="lazy" src={core1} alt="core1"></img>
              <p className='h5 fw-bold core mt-3'>Integrity</p>
              {/* <p>We’re dedicated to being transparent in our dealings, ensuring that you always feel secure and informed.</p> */}
            </div>
            <div className='col-12 col-md-3'>
              <img className='w-25' loading="lazy" src={core2} alt="core2"></img>
              <p className='h5 fw-bold core mt-3'>Excellence</p>
              {/* <p>We take pride in our work, you can trust that we will bring our best selves to every project, striving for perfection.</p> */}
            </div>
            <div className='col-12 col-md-3'>
              <img className='w-25' loading="lazy" src={core3} alt="core3"></img>
              <p className='h5 fw-bold core mt-3'>Client-Centricity</p>
              {/* <p>We tailor our strategies specifically to you, ensuring that you receive personalized support in every step.</p> */}
            </div>
            <div  className='col-12 col-md-3'>
              <img className='w-25' loading="lazy" src={core4} alt="core4"></img>
              <p className='h5 fw-bold core mt-3'>Collaboration</p>
              {/* <p>We are not just consultants, we happily share journey towards achieving your goals.</p> */}
            </div>
            <div className='col-12 col-md-3'>
              <img className='w-25' loading="lazy" src={core5} alt="core5"></img>
              <p className='h5 fw-bold core mt-3'>Responsibility</p>
              {/* <p>When you choose our firm, you're choosing a partner who cares about much more than just financial success.</p> */}
            </div>
        </div>
      </div>

      {/* <div className='cust-container' style={{ paddingTop: "60px", paddingBottom: "60px" }}>
        <p className="section-button">OUR TEAM</p> 
        <div className='row d-flex justify-content-between team'>
            <div className='col-12 col-md-3 team-col'>
                <img loading="lazy" src={team1} alt='team1'></img>
                <div className="team-container">
                  <p className="h3 team-name">Chili Mili</p>
                  <p className="team-title">CEO & Founder</p>
                </div>
            </div>

            <div className='col-12 col-md-3 team-col team-col2'>
                <img loading="lazy" src={team2} alt='team2'></img>
                <div className="team-container">
                  <p className="h3 team-name">Chili Mili</p>
                  <p className="team-title">CEO & Founder</p>
                </div>
            </div>

            <div className='col-12 col-md-3 team-col team-col2'>
                <img loading="lazy" src={team3} alt='team3'></img>
                <div className="team-container">
                  <p className="h3 team-name">Chili Mili</p>
                  <p className="team-title">CEO & Founder</p>
                </div>
            </div>
        </div>
      </div> */}


        {/* <div className='cust-container' style={{paddingTop: "120px"}}>
            <h5 className='htag' style={{textAlign: "center"}}><span style={{fontWeight: "700"}}>Expert Insights & Inspirations:</span><br/> Read our Latest Articles on Understanding Audit & Accounting Services
</h5><br/>
            <BlogCarousel/>
          </div> */}

          <footer className="footer bg-dark text-white">
            <div className="cust-container">
                <div className="row">
                {/* Footer Bottom */}
                <div className="footer-bottom mt-4 text-center">
                    <p>© 2024 <span className="text-warning">Converging Miinds Management Pvt Ltd.</span> All rights reserved. Designed & Developed by <a href='https://www.digiworq.com/' target='_blank' rel="noreferrer"><span className="text-warning">DIGIWORQ</span></a></p>
                </div>
            </div>
            </div>
        </footer>

      </div>
      


  )
}

export default About