import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css'; // Use this file for custom styling if needed

const Footer = () => {
    return (
        <footer className="footer bg-dark text-white py-3">
            <div className="cust-container">

                {/* Footer Bottom */}
                <div className="footer-bottom text-center">
                    <p>© 2024 <span className="text-warning">Converging Miinds Management Pvt Ltd.</span> All rights reserved. Designed & Developed by <a href='https://www.digiworq.com/' target='_blank' rel="noreferrer"><span className="text-warning">DIGIWORQ</span></a></p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
