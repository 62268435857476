import React, {useState} from "react";
import "./Service8.css";
import { Helmet } from "react-helmet";

const Service1 = () => {

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Advantages of CFO Outsourcing",
      content: (
        <>
          <p>
              Outsourcing your CFO functions offers numerous advantages,
              allowing your business to leverage financial expertise while
              optimizing resources. Here are some key benefits:
            </p>
            <ul className="service1-list">
              <li>
                <span className="bold">Company Law Advisory/Compliances:</span>
                <br />
                Understanding the requirements of the Registrar of Companies (ROC) is crucial for maintaining compliance with the Companies Act. Our team assists businesses in fulfilling these requirements, which include registration, filing annual returns, and maintaining statutory records. Key services include:
                <ul className="service-list">
                  <li>
                    <span className="bold1">Cost Efficiency</span>
                  </li>

                  <li>
                    <span className="bold1">Access to Expertise</span>
                  </li>

                  <li>
                    <span className="bold1">Business Scalability</span>
                  </li>

                  <li>
                    <span className="bold1">
                      Enhanced Focus on Core Business Activities
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
        </>
      ),
    },
    {
      title: "Value Adds of Business Finance Consulting",
      content: (
        <>
          <p>
          Our business finance consulting services complement our CFO outsourcing by providing targeted, strategic financial guidance to help your organization thrive. Here’s how we add value:
            </p>
            <ul className="service-list">
              <li>
                <span>Strategic Financial Planning</span>
              </li>
              <li>
                <span >Risk Management</span>
              </li>
              <li>
                <span >Performance Management</span>
              </li>

              <li>
                <span >Cash Flow Management</span>
              </li>

              <li>
                <span> Financial Reporting and Analysis</span>
              </li>
            </ul>
        </>
      ),
    },
    {
      title: "Tailoured Strategic Planning for Every Business",
      content: (
        <>
          <p>
          At Cmiinds, we recognize that every business is unique. Our CFO outsourcing and business financial consulting services are tailoured to fit your specific needs. Whether you're a startup seeking foundational financial guidance or an established enterprise looking for sophisticated financial strategies, our services can be customized to meet your organization’s requirements.
            </p>
            <ul className="service-list">
              <li>
                <span >SEZ Registration and Compliance</span>
              </li>
              <li>
                <span>Industry-Specific Expertise</span>
              </li>

              <li>
                <span >Personalized Consulting Approach</span>
              </li>
            </ul>

            <h5 className="htag">Partner with Cmiinds for Financial Success</h5>
            <p>
              Choosing to engage in CFO outsourcing and business finance
              consulting is a strategic move that can significantly influence
              your organization’s trajectory. By partnering with Cmiinds, you
              gain access to a wealth of financial expertise, tailoured
              solutions, and strategic insights that empower your business to
              grow and thrive in a competitive landscape.
            </p>
        </>
      ),
    },

  ];


  return (
    <div>
      <Helmet>
        <title>Converging Miinds</title>
        <meta
          name="CFO Outsourcing & Business Finance Consulting Services"
          content="Enhance your business efficiency with our CFO outsourcing and finance consulting services. Expert support to drive strategic financial growth."
        />
        <meta
          name="keywords"
          content="CFO Outsourcing, Financial Planning, Financial Consulting, Cash-flow management, Finance management"
        />
      </Helmet>
      {/* <div className="services8-banner">
        <div className="banner-content cust-container">
          <h1 className="sub-banner-htag services8">
            CFO Outsourcing & Business Finance Consulting
            <br />
            <span className="highlight">
              {" "}
              With Strategic Financial Management and Insights
            </span>
          </h1>
        </div>
      </div> */}

      <div className="cust-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Services">Services</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              CFO Outsourcing/Business Finance Consulting
            </li>
          </ol>
        </nav>
      </div>

      <div className="cust-container bnr-img" style={{ paddingTop: "60px" }}>
        <img src="https://res.cloudinary.com/dvi7lnk6r/image/upload/v1731940322/08_CFO-Outsourcing_igs9fq.jpg" alt="incorporation" className="w-25 mx-auto"></img>
      </div>

      <div className="cust-container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-md-12">
            <p>
            In today’s dynamic business landscape, skilled financial leadership is more critical than ever. At Cmiinds, we provide tailored CFO outsourcing and business finance consulting services to meet your organization’s specific needs. With our experts managing your financial strategy, you can focus on core operations while leveraging strategic insights to drive growth and operational efficiency.
            </p>
            
          </div>

        </div>
      </div>

      <div
        className="cust-container"
        style={{ paddingTop: "20px", paddingBottom: "75px" }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="tabs-container">
              <div className="tabs-header">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`tab-button ${
                      activeTab === index ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
              <div className="tab-content">{tabs[activeTab].content}</div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer bg-dark text-white">
        <div className="cust-container">
          <div className="row">
            {/* Footer Bottom */}
            <div className="footer-bottom mt-4 text-center">
              <p>
                © 2024{" "}
                <span className="text-warning">
                  Converging Miinds Management Pvt Ltd.
                </span>{" "}
                All rights reserved. Designed & Developed by{" "}
                <a
                  href="https://www.digiworq.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-warning">DIGIWORQ</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>

      

    </div>
  );
};

export default Service1;
