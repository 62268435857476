import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Services from './Components/Services/Services';
import KnowHow from './Components/KnowHow/KnowHow';
import Career from './Components/Career/Career';
import Contact from './Components/Contact/Contact';
import Navbar from './Components/Navbar/Navbar';
import Service9 from './Components/Service9/Service9';
import Service2 from './Components/Service2/Service2';
import Service1 from './Components/Service1/Service1';
import Service3 from './Components/Service3/Service3';
import Service4 from './Components/Service4/Service4';
import Service5 from './Components/Service5/Service5';
import Service6 from './Components/Service6/Service6';
import Service7 from './Components/Service7/Service7';
import Service8 from './Components/Service8/Service8';
import KnowHowDet1 from './Components/KnowHow/KnowHowDet1';
import KnowHowDet2 from './Components/KnowHow/KnowHowDet2';
import PrivacyPolicy from './Components/Pp_Tc/PrivacyPolicy';
import Terms from './Components/Pp_Tc/Terms';
import ScrollToTop from './ScrolltoTop';


function App() {
  return (
    <div className='App'>
        <Navbar/>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/About" element={<About />} />
          <Route exact path="/Services" element={<Services />} />
          <Route exact path="/KnowHow" element={<KnowHow />} />
          <Route exact path="/Career" element={<Career />} />
          <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/Services/Corporate-Finance" element={<Service9/>} />
          <Route exact path="/Services/BPO" element={<Service2/>} />
          <Route exact path="/Services/Incorporation-and-Startup-Support" element={<Service1/>} />
          <Route exact path="/Services/Audit" element={<Service3/>} />
          <Route exact path="/Services/Payroll-Processing" element={<Service4/>} />
          <Route exact path="/Services/Direct-Taxation" element={<Service5/>} />
          <Route exact path="/Services/Indirect-Tax" element={<Service6/>} />
          <Route exact path="/Services/Regulatory" element={<Service7/>} />
          <Route exact path="/Services/CFO-Outsourcing-Business-Finance-Consulting" element={<Service8/>} />
          <Route exact path="/KnowHowBlog1" element={<KnowHowDet1/>} />
          <Route exact path="/KnowHowBlog2" element={<KnowHowDet2/>} />
          <Route exact path="/Privacy-Policy" element={<PrivacyPolicy/>} />
          <Route exact path="/Terms-and-Conditions" element={<Terms/>} />
        </Routes>
        <ScrollToTop/>
    </div>
  );
}

export default App;
