import React, { useState } from "react";
import "./Service1.css";
import { Helmet } from "react-helmet";

const Service1 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: " Incorporation Services",
      content: (
        <>
          <p>
          Incorporation is the first step toward establishing your business as a legal entity. Choosing the right structure is crucial as it impacts your liabilities, taxes, and ability to raise capital. Our incorporation services include:
          </p>
          <ul className="service1-list">
            <li>
              <span className="bold">
                Choosing the Right Business Structure:
              </span>
              <br />
              Selecting the right business structure is vital when launching your venture. Whether it’s a sole proprietorship, One Person Company (OPC), partnership, Limited Liability Partnership (LLP), or Private Limited Company, each has its own advantages and challenges, particularly regarding liability, taxation, and administration. Our expert team is here to help you navigate these factors and choose the best structure for your startup.
            </li>

            <li>
              <span className="bold">Seamless Incorporation Process:</span>
              <br />
              Once you’ve decided on a business structure, we facilitate the incorporation process by handling all necessary paperwork and legal filings. Our services include:
              <ul className="service-list">
                <li>
                  <span className="bold1">Drafting Key Documents</span>
                </li>

                <li>
                  <span className="bold1">
                  Filing of application and obtaining Necessary approvals, registrations and Licenses
                  </span>
                </li>

                <li>
                  <span className="bold1">
                  Registration with other Authorities for PF, ESI, PT, IEC etc
                  </span>
                </li>

                <li>
                  <span className="bold1">Assist in opening up of a Bank Account and its setup</span>
                </li>

                <li>
                  <span className="bold1">Assist in pre and post compliance in case of Private Limited Company</span>
                </li>
              </ul>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Statutory Regulations",
      content: (
        <>
          <p>
          Compliance with statutory regulations is essential for any business operating in today’s complex regulatory environment. At Cmiinds, we provide expertise in understanding and implementing necessary compliance measures:
          </p>
          <ul className="service-list">
            <li>
              <span>
              Understanding Regulatory Obligations and timeline
              </span>
            </li>
            <li>
              <span>
              Annual Filings with Regulatory Authorities and timeline
              </span>
            </li>
            <li>
              <span >Corporate Governance Regulations</span>
            </li>
            <li>
              <span>Licensing Requirements</span>
            </li>

            <li>
              <span >Ongoing Compliance Monitoring</span>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Internal Control Setup",
      content: (
        <>
          <p>
          Establishing a robust internal control system is vital for any startup. Effective internal controls help in safeguarding assets, ensuring accurate financial reporting, and promoting operational efficiency. Our services in internal control setup include:
          </p>
          <ul className="service1-list">
            <li>
              <span className="bold">Assessing Your Current Controls</span>
            </li>

            <li>
              <span className="bold">Risk Assessment and Management</span>
            </li>

            <li>
              <span className="bold">
                Designing Tailoured Internal Controls:
              </span>
              <br />
              After assessing your needs, we will design internal controls that suit your business operations. Key elements may include:
              <ul className="service-list">
                <li>
                  <span className="bold1">Financial Controls</span>
                </li>
                <li>
                  <span className="bold1">Operational Controls</span>
                </li>
                <li>
                  <span className="bold1">Compliance Controls</span>
                </li>
              </ul>
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Converging Miinds</title>
        <meta
          name="Seamless Incorporation and Startup Services"
          content="Start Your Business With Confidence. Our Expert Services In Incorporation, Statutory Regulations, And Internal Control Setup Ensure A Smooth Launch For Your Startup."
        />
        <meta
          name="keywords"
          content="Startup services, Startup support, Incorporation services, Statutory regulations, Internal control support"
        />
      </Helmet>
      {/* <div className="services1-banner">
        <div className="banner-content cust-container">
          <h1 className="sub-banner-htag services9">
            Comprehensive Incorporation Services, Statutory{" "} 
            <span className="highlight">
              Compliance, And Internal Control Setup{" "}
            </span>
          </h1>
        </div>
      </div> */}

      <div className="cust-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Services">Services</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Incorporation and Startup Support
            </li>
          </ol>
        </nav>
      </div>

      <div className="cust-container bnr-img" style={{ paddingTop: "60px" }}>
        <img src="https://res.cloudinary.com/dvi7lnk6r/image/upload/v1731940322/01_incorporation-_-Startup-Support_n0nvpi.jpg" alt="incorporation" className="w-25 mx-auto"></img>
      </div>

      
      <div className="cust-container" style={{ paddingTop: "60px" }}>
      <p>Starting a business can be overwhelming with its legal and operational complexities. At Cmiinds, we offer a full range of incorporation and startup services to simplify the process. With our expert support, you can focus on your vision and operations while we handle the regulatory and structural essentials for your success.</p>
      </div>

      <div className="cust-container" style={{ paddingTop: "60px", paddingBottom: "50px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="tabs-container">
              <div className="tabs-header">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`tab-button ${
                      activeTab === index ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
              <div className="tab-content">{tabs[activeTab].content}</div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer bg-dark text-white">
        <div className="cust-container">
          <div className="row">
            {/* Footer Bottom */}
            <div className="footer-bottom mt-4 text-center">
              <p>
                © 2024{" "}
                <span className="text-warning">
                  Converging Miinds Management Pvt Ltd.
                </span>{" "}
                All rights reserved. Designed & Developed by{" "}
                <a
                  href="https://www.digiworq.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-warning">DIGIWORQ</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Service1;
