import React, { useState } from "react";
import "./Service4.css";
import { Helmet } from "react-helmet";

const Service1 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Setting Up a Payroll Process",
      content: (
        <>
          <p>
          Establishing a solid payroll process is the cornerstone of efficient payroll management. Our experienced team guides you through setting up a customized payroll system that meets your organizational needs, ensuring a smooth implementation. Key elements include:
          </p>
          <ul className="service-list">
            <li>
              <span>Understanding Business Needs</span>
              </li>
              <li>
                <span >Integration with Existing Systems</span>
              </li>
              <li>
                <span >Error-Free Data Entry</span>
              </li>
              <li>
                <span>Training and Support</span>
              </li>
          </ul>
        </>
      ),
    },
    {
      title: "Payroll Structuring for Enhanced Efficiency",
      content: (
        <>
          <p>
            A well-structured payroll system not only enhances accuracy but also
            contributes to overall employee satisfaction. At Cmiinds, we focus
            on creating a payroll structure that optimizes your administration
            and responds to your specific business requirements, featuring:
          </p>
          <ul className="service-list">
            <li>
              <span >Flexible Pay Structures</span></li>
              <li>
                <span>
                  Comprehensive Benefits and Deductions
                </span>
              </li>
              <li>
                <span >Configurable Reporting</span>
              </li>
              <li>
                <span >Time Tracking Integration</span>
              </li>
            
          </ul>
        </>
      ),
    },
    {
      title: "Web-Based Employee Access for Transparency",
      content: (
        <>
          <p>
            To enhance employee engagement and transparency, we provide a
            web-based access platform for employees to view their pay slips and
            loan statements. This innovative solution includes:
          </p>
          <ul className="service-list">
            <li>
              <span>Secure Employee Portal</span></li>
              <li>
                <span >Loan Statement Accessibility</span>
              </li>
              <li>
                <span>User-Friendly Interface</span>
              </li>
              <li>
                <span >24/7 Availability</span>
              </li>
            
          </ul>
        </>
      ),
    },
    {
      title: "Compliance Management for Peace of Mind",
      content: (
        <>
          <p>
            Navigating the complex landscape of compliance is crucial for your
            organization. Our payroll services include thorough compliance
            management, focusing on:
          </p>
          <ul className="service-list">
            <li>
              <span>Statutory Deductions</span></li>
              <li>
                <span>Timely Filings</span>
              </li>
              <li>
                <span>Regular Compliance Checks</span>
              </li>
              <li>
                <span>Guidance on Updates</span>
              </li>
            
          </ul>
        </>
      ),
    },

    {
      title: "Online Troubleshooting and Reporting",
      content: (
        <>
          <p>
            Managing payroll efficiently requires timely troubleshooting and
            management reporting. Our approach includes:
          </p>
          <ul className="service-list">
            <li>
              <span>Online Troubleshooting Support</span></li>
              <li>
                <span>Tailoured Reporting</span>
              </li>
              <li>
                <span >Audit Trails and Compliance</span>
              </li>
            
          </ul>
        </>
      ),
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Converging Miinds</title>
        <meta
          name="Efficient Payroll Processing Services for Your Business"
          content="Streamline your payroll with our experts, including structuring, compliance management, and employee access to payslips—ensuring accuracy and efficiency."
        />
        <meta
          name="keywords"
          content="Payroll management, Payroll processing, Compliance management, Troubleshooting support, Statutory Obligations"
        />
      </Helmet>
      {/* <div className="services-banner">
        <div className="banner-content cust-container">
          <h1 className="sub-banner-htag services4">
          Streamline Your Payroll Management with Efficient, 

            <br />
            <span className="highlight"> Complaint and Tailoured Payroll Processing Services</span>
          </h1>
        </div>
      </div> */}

      <div className="cust-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Services">Services</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Payroll Processing
            </li>
          </ol>
        </nav>
      </div>

      <div className="cust-container bnr-img" style={{ paddingTop: "60px" }}>
        <img src="https://res.cloudinary.com/dvi7lnk6r/image/upload/v1731940323/04_Payroll-Processing_zauppw.jpg" alt="incorporation" className="w-25 mx-auto"></img>
      </div>

      <div className="cust-container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-md-12">
            <p>
            At Cmiinds, we recognize that effective payroll processing is essential for any organization’s success. Our dedicated payroll services are designed to simplify your payroll tasks, enhance accuracy, and ensure compliance with relevant regulations. Streamlining your payroll processes allows you to focus more on your business while we manage this critical function seamlessly.
            </p>
          </div>
        </div>
      </div>


      <div className="cust-container" style={{ paddingTop: "20px", paddingBottom: "100px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="tabs-container">
              <div className="tabs-header">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`tab-button ${
                      activeTab === index ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
              <div className="tab-content">{tabs[activeTab].content}</div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer bg-dark text-white">
        <div className="cust-container">
          <div className="row">
            {/* Footer Bottom */}
            <div className="footer-bottom mt-4 text-center">
              <p>
                © 2024{" "}
                <span className="text-warning">
                  Converging Miinds Management Pvt Ltd.
                </span>{" "}
                All rights reserved. Designed & Developed by{" "}
                <a
                  href="https://www.digiworq.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-warning">DIGIWORQ</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>

      
    </div>
  );
};

export default Service1;
