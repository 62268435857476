import React, {useEffect, useState} from 'react';
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Banner  from '../../Assets/HomeBanner.jpg';
// import Banner2  from '../../Assets/HomeBanner1.jpg';
// import Banner3  from '../../Assets/HomeBanner2.jpg';
// import video  from 'https://res.cloudinary.com/dvi7lnk6r/video/upload/v1732011264/video_ewaxqb.mp4';
// import Quote  from '../../Assets/quote.png';
// import HomeCarousel from '../../Components/OwlCarousel/Home1'
// import BlogCarousel from '../../Components/OwlCarousel/Home3'
// import OfferCarousel from '../../Components/OwlCarousel/Home2'
// import TestiCarousel from '../../Components/OwlCarousel/Home4'
import './Home.css';
import { Helmet } from 'react-helmet';
// import blg1 from '../../Assets/blogmain1.jpg'
// import blg2 from '../../Assets/blogmain2.jpg'
import WOW from 'wowjs';
import 'animate.css'; // WOW.js relies on Animate.css for animations.


const Home = () => {

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);




  const messages = [
    "Excellence in Numbers Trust in Service",
    "Integrity in Solutions Growth in Trust",
    "Custom Audit and Accounting Solutions",
  ];

  // State to manage the current message index
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  // Function to split the message after the third word
  const splitMessage = (message) => {
    const words = message.split(" ");
    const firstLine = words.slice(0, 3).join(" "); // First 3 words
    const secondLine = words.slice(3).join(" "); // Remaining words
    return { firstLine, secondLine };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Change the message index every 3 seconds
      setCurrentMessageIndex((prevIndex) =>
        prevIndex === messages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // 3000ms = 3 seconds

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, [messages.length]);

  // Get the current message and split it
  const { firstLine, secondLine } = splitMessage(messages[currentMessageIndex]);

  const messageClass = ["onee", "twoo", "threee"][currentMessageIndex];


  return (
    <div>
    <Helmet>
        <title>Converging Miinds</title>
        <meta name="Expert Financial Consulting & Accounting Outsourcing Services" content="Experience Top-notch financial consulting, accounting outsourcing, and auditing services at Converging Miinds. Streamline your business with our expert solutions!" />
        <meta name="keywords" content="financial consulting, Business Process Outsourcing, accounting outsourcing, auditing service, financial planning" />
      </Helmet>

      <div className="banner-video-container">
        <video
          className="banner-video"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src='https://res.cloudinary.com/dvi7lnk6r/video/upload/v1732011264/video_ewaxqb.mp4' type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-overlay"></div>
        <div className="overlay-content">
          {/* Render the current message */}
          <p
            className={`banner-htag ${messageClass}`}
          >
            {firstLine} <br /> {secondLine}
          </p>
          <a href="/About">
            <button className="cta-button mb-3">Explore Now</button>
          </a>
      </div>

      </div>

      <div className='cust-container' style={{paddingBottom: "95px"}}>
        <div className="row d-flex justify-content-between top-mar">
          <div className="col-md-3 col-sm-12 below-banner one">
              <p className="h3">Pioneering Your Path to Financial Success With Expert Accounting and Regulatory Compliance.</p>
          </div>
          <div className="col-md-3 col-sm-12 below-banner two">
              <p className="h3">Innovating Financial Strategies with Expert CFO Outsourcing and Financial Consulting Services.</p>
          </div>
          <div className="col-md-3 col-sm-12 below-banner three ">
              <p className="h3">Dedicated Support for Startups with Tax Planning and Business Process Outsourcing Services.</p>
          </div>
        </div>
      </div>
    

      <footer className="footer bg-dark text-white">
            <div className="cust-container">
                <div className="row">
                {/* Footer Bottom */}
                <div className="footer-bottom mt-4 text-center">
                    <p>© 2024 <span className="text-warning">Converging Miinds Management Pvt Ltd.</span> All rights reserved. Designed & Developed by <a href='https://www.digiworq.com/' target='_blank' rel="noreferrer"><span className="text-warning">DIGIWORQ</span></a></p>
                </div>
            </div>
            </div>
        </footer>

    </div>
    
  );
};

export default Home