import React, {useState} from "react";
import "./Service9.css";
import { Helmet } from "react-helmet";

const Service1 = () => {


  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Comprehensive Corporate Finance Solutions",
      content: (
        <>
        <p>
          Our corporate finance services encompass a wide array of offerings
          designed to support your organization in achieving its strategic
          objectives. Below, we delve into our core areas of expertise that
          enhance your financial capabilities and foster growth opportunities.
        </p>
        <h3 className="htag">Strategy Development</h3>
            <ul className="service-list">
              <li>
                <span>Understanding Your Goals</span>
              </li>

              <li>
                <span >Analyzing Market Opportunities</span>
              </li>

              <li>
                <span >Creating a Financial Roadmap</span>
              </li>
            </ul>
        </>
      ),
    },
    {
      title: "Capital Structuring and Fundraising",
      content: (
        <>
        <ul className="service-list">
                <li>
                  <span >
                    Optimizing Your Capital Structure
                  </span>
                </li>

                <li>
                  <span>Fundraising Strategies</span>
                </li>

                <li>
                  <span >Fundraising Strategies</span>
                </li>
                <li>
                  <span>Crafting Compelling Proposals</span>
                </li>
                </ul>
        </>
      ),
    },
    {
      title: "Project Financing",
      content: (
        <>
         <ul className="service-list">
              <li>
                <span>Financing Complex Projects</span>
              </li>

              <li>
                <span >Risk Assessment and Management</span>
              </li>

              <li>
                <span>Structuring Financing Solutions</span>
              </li>

              <li>
                <span >Crafting Compelling Proposals</span>
              </li>
            </ul>
        </>
      ),
    },

    {
      title: "Deal Sourcing",
      content: (
        <>
          <ul className="service1-list">
          <li>
            <span>Identifying Strategic Opportunities</span>
          </li>

          <li>
            <span>Conducting Due Diligence</span>
          </li>

          <li>
            <span >Negotiation and Execution</span>
            <br /><br />
            Through our extensive knowledge of the market and our network of
            contacts, CMiinds is well positioned to:
            <ul className="service-list padng">
              <li>
                Conduct screening of selected sectors for attractive
                opportunities
              </li>
              <li>Initiate contacts with potential target companies</li>
              <li>
                Propose viable and interesting opportunities private equity and
                venture capital firms
              </li>
            </ul>
          </li>
        </ul>
        </>
      ),
    },

  ];
  return (
    <div>
      <Helmet>
        <title>Converging Miinds</title>
        <meta
          name="Strategic Corporate Finance Solutions for Your Business Growth"
          content="Discover Cmiinds expert corporate finance services, including strategy development, capital structuring, project financing services, and deal sourcing to drive growth."
        />
        <meta
          name="keywords"
          content="Corporate Finance, Project Financing Services, FInancial Statements, Fundraising Strategies, Financial Strategy planning"
        />
      </Helmet>

      <div className="cust-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Services">Services</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Corporate Finance
            </li>
          </ol>
        </nav>
      </div>

      <div className="cust-container bnr-img" style={{ paddingTop: "60px" }}>
        <img src="https://res.cloudinary.com/dvi7lnk6r/image/upload/v1731940322/09_Corporate--Finance_uk6fly.jpg" alt="incorporation" className="w-25 mx-auto"></img>
      </div>

      <div className="cust-container" style={{ paddingTop: "60px" }}>
      <div className="row">
            <div className="col-md-12">
            <p>
            At Cmiinds, we recognize that effective corporate finance is vital for driving growth and ensuring long-term sustainability. Our dedicated team of professionals is here to provide comprehensive corporate finance services tailored to your unique business needs. From strategy development to capital structuring and deal sourcing, we offer the expertise and support necessary to navigate today’s complex financial landscape.
        </p>
       
            </div>
      </div>
        
      </div>


      <div
        className="cust-container"
        style={{ paddingTop: "60px", paddingBottom: "80px" }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="tabs-container">
              <div className="tabs-header">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`tab-button ${
                      activeTab === index ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
              <div className="tab-content">{tabs[activeTab].content}</div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer bg-dark text-white">
        <div className="cust-container">
          <div className="row">
            {/* Footer Bottom */}
            <div className="footer-bottom mt-4 text-center">
              <p>
                © 2024{" "}
                <span className="text-warning">
                  Converging Miinds Management Pvt Ltd.
                </span>{" "}
                All rights reserved. Designed & Developed by{" "}
                <a
                  href="https://www.digiworq.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-warning">DIGIWORQ</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>

      



    </div>
  );
};

export default Service1;
