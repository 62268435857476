import React, { useState } from "react";
import "./Service6.css";
import { Helmet } from "react-helmet";

const Service1 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "GST Compliance and Advisory services",
      content: (
        <>
          <p>
          GST is a critical component of indirect taxation that businesses must navigate effectively. Our GST services ensure that your organization complies with all relevant regulations while also identifying opportunities for tax optimization. Key aspects include:
          </p>
          <ul className="service-list">
            <li>
              <span>Assessment and Registration</span>
            </li>
            <li>
              <span >
              GST Return Preparation and timely filings
              </span>
            </li>

            <li>
              <span>Tax Advisory Services</span>
            </li>

            <li>
              <span >Input Tax Credit Management</span>
            </li>

            <li>
              <span >Audit and Representation before GST Authorities</span>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "Central Excise & Customs Expertise",
      content: (
        <>
          <p>
          Businesses involved in manufacturing and import/export activities must comply with central excise and customs regulations. Our services in this domain help you navigate the rules effectively, ensuring that your business remains compliant while minimizing tax liabilities.
          </p>
          <ul className="service-list">
            <li>
              <span >
                Central Excise Registration and Compliance
              </span></li>
              <li>
                <span >Customs Clearance Services</span>
              </li>
              <li>
                <span >Duty Drawback Claims</span>
              </li>
              <li>
                <span>Tariff Classification Consulting</span>
              </li>
            
          </ul>
        </>
      ),
    }

  ];

  return (
    <div>
      <Helmet>
        <title>Converging Miinds</title>
        <meta
          name="Expert Direct Taxation Services for Your Business"
          content="Navigate the complexities of direct taxation with our professional services including compliance, advisory, and transfer pricing strategies for optimal tax efficiency."
        />
        <meta
          name="keywords"
          content="indirect taxation, VAT Compliance, Service Tax Compliance, Central Excise, Custom Expertise"
        />
      </Helmet>

      <div className="cust-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Services">Services</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Indirect Tax
            </li>
          </ol>
        </nav>
      </div>

      <div className="cust-container bnr-img" style={{ paddingTop: "60px" }}>
        <img src="https://res.cloudinary.com/dvi7lnk6r/image/upload/v1731940324/06_Indirect-Tax_qtuq22.jpg" alt="incorporation" className="w-25 mx-auto"></img>
      </div>

      <div className="cust-container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-md-12">
            <p>
            At Cmiinds, we understand the complexities of indirect tax and its impact on your business operations. Our team of experts delivers tailored solutions to ensure compliance, optimize tax liabilities, and streamline your indirect tax processes. We specialize in Goods and Services Tax (GST) and Central Excise & Customs, offering services designed to meet your unique business needs.
            </p>
          </div>
        </div>
      </div>


      <div
        className="cust-container"
        style={{ paddingTop: "20px", paddingBottom: "100px" }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="tabs-container">
              <div className="tabs-header">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`tab-button ${
                      activeTab === index ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
              <div className="tab-content">{tabs[activeTab].content}</div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer bg-dark text-white">
        <div className="cust-container">
          <div className="row">
            {/* Footer Bottom */}
            <div className="footer-bottom mt-4 text-center">
              <p>
                © 2024{" "}
                <span className="text-warning">
                  Converging Miinds Management Pvt Ltd.
                </span>{" "}
                All rights reserved. Designed & Developed by{" "}
                <a
                  href="https://www.digiworq.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-warning">DIGIWORQ</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>

      
    </div>
  );
};

export default Service1;
