import React, { useState } from "react";
import "./Service2.css";
import { Helmet } from "react-helmet";

const Service1 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Process Planning",
      content: (
        <>
          <p>
          Effective process planning is the cornerstone of successful accounting outsourcing. At Cmiinds, we focus on crafting streamlined workflows to optimize your financial operations. By thoroughly evaluating your current processes, we uncover opportunities for improvement, empowering your business to achieve enhanced efficiency and precision.
          </p>
          <p className="h3">Steps to Effective Process Planning</p>
          <ul className="service-list">
            <li>
              <span>Assessment of Current Processes</span>
            </li>

            <li>
              <span>Designing Streamlined Workflows</span>
            </li>

            <li>
              <span>Utilization of Advanced Technology</span>
            </li>

            <li>
              <span>Ongoing Monitoring and Improvement</span>
            </li>
          </ul>
          <p>By investing in meticulous process planning, we enable your organization to reduce costs, enhance productivity, and improve overall financial accuracy.</p>

        </>
      ),
    },
    {
      title: "Cmiinds Guarantee",
      content: (
        <>
          <p>
          We are committed to provide exceptional accounting outsourcing services that uphold the highest standards of quality and integrity. With a steadfast commitment to excellence, we go beyond being service providers—we become your trusted partners in success.
          </p>
          <p className="h3">Key Aspects of Our Guarantee</p>
          <ul className="service-list">
            <li>
              <span>Dedicated Expertise</span>
            </li>

            <li>
              <span >Tailoured Solutions</span>
            </li>

            <li>
              <span>Transparent Communication</span>
            </li>

            <li>
              <span >Performance Metrics</span>
            </li>

            <li>
              <span>Risk Mitigation</span>
            </li>
          </ul>
          <p>
            With our unwavering commitment to excellence, you can trust Cmiinds
            to become an integral part of your financial journey.
          </p>
        </>
      ),
    },
    {
      title: "Our Comprehensive Range of Accounts outsourcing / BPO Solutions",
      content: (
        <>
        <div className="row">
          <div className="col-md-6">
          <ul className="service1-list">
            <li>
              <span className="bold"> Bookkeeping Services:</span>
              <br />
              <ul className="service-list padng">
                <li>Day-to-day transaction recording</li>
                <li>Bank reconciliations, vendors payments, receivables, fixed assets registers</li>
                <li>Monthly and annual financial statements</li>
              </ul>
            </li>

            <li>
              <span className="bold"> Payroll Processing:</span>
              <br />
              <ul className="service-list padng">
                <li>Salary structuring to maximum tax benefits to the employees</li>
                <li>Timely payroll calculations and processing considering all statutory withholdings</li>
                <li>Ensuring salaries are paid on PAY-DAY</li>
                <li>Tax withholdings, timely remittance and filings</li>
                <li>Timely compliance with labor laws</li>
                <li>Dedicated web access to employees for downloading payslip, salary statements, Form 16 etc</li>
                <li>Dedicated web access to employees for Ticketing.</li>
                <li>Employee Tickets are closed within 2 working days</li>
              </ul>
            </li>

            
            </ul>
          </div>
          <div className="col-md-6">
          <ul className="service1-list">
          <li>
              <span className="bold"> Financial Reporting:</span>
              <br />
              <ul className="service-list padng">
                <li>Comprehensive monthly, quarterly, and annual reports to the management</li>
                <li>Custom reports tailored to your specific needs</li>
                <li>Performance analysis and insights</li>
              </ul>
            </li>
          <li>
              <span className="bold"> Tax Preparation and Planning:</span>
              <br />
              <ul className="service-list">
                <li>Expert tax preparation services</li>
                <li>Tax planning strategies to optimize benefits</li>
                <li>Audit support and representation</li>
              </ul>
            </li>

            <li>
              <span className="bold"> Financial Analysis and Advisory:</span>
              <br />
              <ul className="service-list">
                <li>In-depth analysis of financial performance</li>
                <li>Key performance indicator (KPI) tracking</li>
                <li>Strategic recommendations for improvement</li>
              </ul>
            </li>
          </ul>
          </div>
        </div>
          

           
        </>
      ),
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Converging Miinds</title>
        <meta
          name="Top-Notch Accounting Outsourcing Services in Bangalore"
          content="Cmiinds offers tailoured accounting outsourcing services, including bookkeeping, payroll, and financial reporting, to enhance efficiency and support growth."
        />
        <meta
          name="keywords"
          content="Accounting outsourcing services, Accounting outsourcing, Tax Compliance, Better Financial operations, Payroll processing services"
        />
      </Helmet>
      {/* <div className="services2-banner">
        <div className="banner-content cust-container">
          <h1 className="sub-banner-htag services2">
            Expert Services for
            <br />
            <span className="highlight">Financial Growth and Success</span>
          </h1>
        </div>
      </div> */}

      <div className="cust-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Services">Services</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              BPO
            </li>
          </ol>
        </nav>
      </div>

      
      <div className="cust-container bnr-img" style={{ paddingTop: "60px" }}>
        <img src="https://res.cloudinary.com/dvi7lnk6r/image/upload/v1731940323/02_BPO_gglz44.jpg" alt="incorporation" className="w-25 mx-auto"></img>
      </div>

      <div className="cust-container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-md-12">
            <p>
            At Cmiinds, we prioritize our clients, fostering long-term relationships built on trust and shared success. By partnering with us for your accounting outsourcing needs, you can maximize efficiency and focus on growth. Our dedicated team will guide you through the complexities of financial operations, ensuring accuracy and compliance every step of the way.
            </p>
            <h2 className="htag">
              Establish Your True Efficiency with Our Comprehensive BPO
              Solutions
            </h2>
            <p>
              We provide top-tier accounting outsourcing services designed to
              streamline your financial processes, enhance accuracy, and
              facilitate strategic growth. <br /> By leveraging our expertise,
              businesses can reduce operational costs and focus on core
              activities, ensuring that they remain competitive in an
              ever-evolving market. Explore how our tailoured accounting
              outsourcing solutions can transform your financial operations.
            </p> <br/>
            <p>We offer premium accounting outsourcing services that streamline your financial processes, improve accuracy, and drive strategic growth. By leveraging our expertise, businesses can cut operational costs and focus on core activities, staying competitive in a dynamic market. Discover how our customized accounting solutions can transform your financial operations.</p>

          </div>

        </div>
      </div>

      <div className="cust-container" style={{ paddingTop: "20px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="tabs-container">
              <div className="tabs-header">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`tab-button ${
                      activeTab === index ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
              <div className="tab-content">{tabs[activeTab].content}</div>
            </div>
          </div>
        </div>
      </div>

      

      <footer className="footer bg-dark text-white">
        <div className="cust-container">
          <div className="row">
            {/* Footer Bottom */}
            <div className="footer-bottom mt-4 text-center">
              <p>
                © 2024{" "}
                <span className="text-warning">
                  Converging Miinds Management Pvt Ltd.
                </span>{" "}
                All rights reserved. Designed & Developed by{" "}
                <a
                  href="https://www.digiworq.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-warning">DIGIWORQ</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Service1;
